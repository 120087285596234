import { VT_API } from "./config/hydraAPI";

export const getToken = async () => {
  const result = await VT_API()
    .post("Token", {
      username: "a.gomez@visiontravel.net",
      password: "4n4M4r14.321",
    })
    .catch((error) => {
      return error.response ? error.response : error;
    });

  if (result.status === 200) {
    return { success: true, data: result.data };
  } else {
    return { success: false };
  }
};
