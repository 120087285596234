import React from "react";
import Navbar from "./global-components/navbar1";
import PageHeader from "./global-components/page-header";
import ContactForm from "./section-components/contact-form";
import Footer from "./global-components/footer";
import { useTranslation } from "react-i18next";
import useCountries from "../hooks/useContries";
import useContactUsType from "../hooks/useContactUsType";
import useContactUs from "../hooks/useContactUs";
import { useUser } from "../context/user";

const ContactV1 = () => {
  const { t } = useTranslation();
  const { countries } = useCountries();
  const { contactUsType } = useContactUsType();
  const { form, sendInformation } = useContactUs();
  const { user, logOut} = useUser();
  return (
    <div>
      <Navbar user={user} logOut={logOut} />
      <PageHeader headertitle={t("Contact")} subheader={t("Contact")} t={t} />
      <ContactForm
        countries={countries}
        contactUsType={contactUsType}
        form={form}
        sendInformation={sendInformation}
        t={t}
      />
      <Footer t={t} />
    </div>
  );
};

export default ContactV1;
