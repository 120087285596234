import { useQuery } from "react-query";
import { useToken } from "../context/tokenVT";
import { get } from "../services/api/countries";

const useCountries = () => {
  const { tokenVT } = useToken();
  const { data: countries, isLoading } = useQuery("countries", {
    queryFn: async () => {
      const response = await get(tokenVT);
      return response;
    },
    enabled: tokenVT !== undefined,
  });

  return {
    countries,
    isLoading,
  };
};

export default useCountries;
